
import $ from 'jquery';

function getPostsAsync(key = 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX', callback = function() {}) {

	if (typeof callback !== 'function') callback = () => {};

	if (!key) {
		callback(false, "Please include a valid key in the form: XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX");
		return;
	}

	$.ajax({
		url: `https://api.curator.io/v1/feeds/${key}/posts`,
		data: {
			api_key: key
		},
		success: function(json) {
			callback(json, false);
		},
		error : function(err) {
			callback(false, err);
		}
	});
};

export default {
	getPostsAsync
}



